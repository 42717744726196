import React from 'react'
import flatMap from '@/utils/flatMap'
import Subscriber from '@/components/contents/Mailchimp/Subscriber'
import * as UI from '@/components/UI'
import {
  Section,
  Inner,
  TitleBar,
  Title,
  CardGrid,
  CardCell,
  CardWrapper,
  CardImageWrapper,
  CardImage,
  CardMain,
  CardTitle,
  CardTags,
  CardTag,
  NewsletterWrapper,
} from './Popular.css'

const Popular = ({ content, mailchimp }) => {
  const featured =
    flatMap(content?.popular_featured, x => x?.article?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  return (
    <Section>
      <Inner>
        <TitleBar>
          <Title>{content.popular_title}</Title>
          <UI.ArrowLink color='aqua' to={`/${content.slug}?all=true`}>
            {content.popular_see_all}
          </UI.ArrowLink>
        </TitleBar>
        <CardGrid>
          {featured.map(article => (
            <CardCell key={article.slug}>
              <CardWrapper to={`${content.slug}/${article.slug}`}>
                <CardImageWrapper>
                  <CardImage
                    alt={article.meta_title}
                    src={article?.meta_image}
                  />
                </CardImageWrapper>
                <CardMain>
                  <CardTitle>{article.meta_title}</CardTitle>
                  <CardTags>
                    {article?.tags?.map(({ tag }) => (
                      <CardTag key={tag}>{tag}</CardTag>
                    ))}
                  </CardTags>
                </CardMain>
              </CardWrapper>
            </CardCell>
          ))}
        </CardGrid>
      </Inner>
      <NewsletterWrapper>
        <Subscriber
          newsletterTitle={content.newsletter_title}
          newsletterLabel={content.newsletter_placeholder}
          successMessage={mailchimp.success_message}
          duplicatedMessage={mailchimp.duplicated_message}
          deletedMessage={mailchimp.deleted_message}
          genericError={mailchimp.generic_error}
        />
      </NewsletterWrapper>
    </Section>
  )
}

export default Popular

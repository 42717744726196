import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-bottom: 4rem;
  ${above('lg')`
    padding-bottom: 5rem;
  `}
`

export const First = styled.div`
  margin-bottom: 1rem;
  ${above('sm')`
    margin-bottom: 1.5rem;
  `}
  ${above('md')`
    margin-bottom: 2rem;
  `}
  ${above('lg')`
    margin-bottom: 4rem;
  `}
`

export const FirstDate = styled.time`
  display: block;
  ${above('md')`
    margin-top: 1rem;
  `}
  ${above('lg')`
    margin-top: 1.5rem;
  `}
`

export const FirstTitle = styled.h2`
  ${above('lg')`
    ${getTypeStyle('subtitle')};
    margin: 0.25rem 0 1rem;
  `}
`

export const FirstDescription = styled.p`
  ${above('md')`
    margin-bottom: 5rem;
  `}
  ${above('lg')`
    margin-bottom: 7.5rem;
  `}
  ${above('xg')`
    margin-bottom: 10rem;
  `}
`

export const Grid = styled(UI.Grid)`
  align-items: stretch;
  height: 100%;
`

export const Cell = styled.div`
  height: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    width: ${getColumnSize(3)};
  `}
`

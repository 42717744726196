import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-top: 1.5rem;
  overflow: hidden;
  ${above('lg')`
    padding-top: 6.25rem;
  `}
`

export const Title = styled.h1`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-bottom: 2.5rem;
  ${getTypeStyle('title')};
  ${above('lg')`
    ${getTypeStyle('mainTitle')};
  `}
`

export const Tags = styled.ul`
  margin: -0.25rem -2rem;
  padding: 2.5rem 0;
  overflow: scroll;
  display: flex;
  flex-wrap: no-wrap;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scrollbar-height: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::before,
  &::after {
    content: '';
    flex: 0 0 1.75rem;
  }
  ${above('lg')`
    flex-wrap: wrap;
    margin: -0.25rem;
    &::before,
    &::after {
      display: none;
    }
  `}
`

export const Tag = styled.li`
  ${getTypeStyle('label')};
  text-transform: uppercase;
  font-weight: 500;
  margin: 0.25rem;
  flex: 0 0 auto;
  padding: 0.25rem 0.5rem;
  color: ${p => p.theme.colors.maastricht};
  background: ${p => p.theme.colors.flash};
  border-radius: 0.125rem;
  position: relative;
  &:hover,
  &:focus &:active {
    &:after {
      opacity: 1;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.125rem;
    left: 0;
    background: ${p => p.theme.colors.aqua};
    opacity: 0;
    transition: 0.2s all;
  }
  ${p =>
    p.current &&
    `
    color: ${p.theme.colors.white};
    background: ${p.theme.colors.aqua};
    &:after {
      background: ${p.theme.colors.maastricht}33;
    }
    &:hover, &:focus &:active {
      color: ${p.theme.colors.white};
    }
  `}
`

export const TagLink = styled(UI.Link)`
  display: block;
  margin: -1rem -0.75rem;
  padding: 1rem 0.75rem;
`

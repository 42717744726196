import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  width: ${getColumnSize(12)};
  margin-bottom: 4rem;
  ${above('lg')`
    margin-top: 2.5rem;
    margin-bottom: 7.5rem;
    width: ${getColumnSize(6)};
  `}
  ${above('xg')`
    width: ${getColumnSize(7)};
  `}
`

export const TitleBar = styled.div`
  margin-bottom: 1.125rem;
  ${above('md')`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 3rem;
  `}
  a {
    padding: 1.25rem 0;
    ${above('md')`
      padding: inherit;
    `}
  }
`

export const Title = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
`

export const CardCell = styled.div`
  width: ${getColumnSize(12)};
`

export const CardWrapper = styled(UI.Link)`
  display: block;
  background: ${p => p.theme.colors.white};
  color: ${p => p.theme.colors.maastricht};
  box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.08);
  border-radius: 0.125rem;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  ${above('sm')`
    display: flex;
  `}
  &:hover,
  &:focus,
  &:active {
    background: ${p => p.theme.colors.aqua};
    color: ${p => p.theme.colors.white};
  }
`

export const CardImageWrapper = styled.div`
  height: 10rem;
  width: 100%;
  overflow: hidden;
  ${above('sm')`
    flex: 3;
    min-width: 10rem;
    height: inherit;
  `}
`

export const CardImage = styled(UI.Image)`
  display: block;
  object-fit: cover;
  object-position: center;
  min-height: 100%:
  max-height: 10rem;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  ${p =>
    p.type !== 'article' &&
    `
    max-height: auto;
    min-height: 14rem;
    ${above('md')`
      min-height: 17rem;
    `}
  `}
`

export const CardMain = styled.div`
  padding: 1.5rem;
  flex: 7;
  ${above('md')`
    padding-right: 4rem;
  `}
  ${above('lg')`
    padding-right: 1.5rem;
  `}
  ${above('xg')`
    padding-right: 4rem;
  `}
`

export const CardDate = styled(UI.Label).attrs({ as: 'time' })`
  display: block;
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 0.75rem;
  ${CardWrapper}:hover &,
  ${CardWrapper}:focus &,
  ${CardWrapper}:active & {
    color: currentColor;
    opacity: 0.8;
  }
`

export const CardTitle = styled(UI.Caption)`
  display: block;
  font-family: ${p => p.theme.type.fonts.headline};
  padding-bottom: 0.75rem;
`

export const CardDescription = styled(UI.SmallText)`
  display: block;
  padding-bottom: 1.5rem;
`

export const CardTags = styled.ul`
  display: flex;
  flex-wrap: wrap;
`

export const CardTag = styled.li`
  display: flex;
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  ${getTypeStyle('label')};
  &:after {
    content: '•';
    margin: 0 0.5rem;
  }
  &:last-child {
    &:after {
      content: none;
    }
  }
  ${CardWrapper}:hover &,
  ${CardWrapper}:focus &,
  ${CardWrapper}:active & {
    color: currentColor;
    opacity: 0.8;
  }
`

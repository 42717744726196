import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import {
  Section,
  First,
  FirstDate,
  FirstTitle,
  FirstDescription,
  Grid,
  Cell,
} from './Featured.css'

const Featured = ({ content }) => {
  const [first, ...rest] =
    flatMap(content?.hero_featured, x => x?.article?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  return (
    <Section>
      <UI.Container>
        {!!first && (
          <First>
            <UI.Card
              to={`${content.slug}/${first.slug}`}
              color='aqua'
              title={<FirstTitle>{first.meta_title}</FirstTitle>}
              date={<FirstDate>{first.date}</FirstDate>}
              description={
                <FirstDescription>{first.meta_description}</FirstDescription>
              }
              image={first?.meta_image}
              tags={first.tags}
              type='article'
              imagePosition='left'
            />
          </First>
        )}
        {!!rest?.length && (
          <Grid vertical-gutter>
            {rest.map(article => (
              <Cell key={article.slug}>
                <UI.Card
                  to={`${content.slug}/${article.slug}`}
                  color='aqua'
                  title={article.meta_title}
                  date={article.date}
                  description={article.meta_description}
                  image={article?.meta_image}
                  tags={article.tags}
                  type='article'
                />
              </Cell>
            ))}
          </Grid>
        )}
      </UI.Container>
    </Section>
  )
}

export default Featured

import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-bottom: 4rem;
  ${above('lg')`
    padding-bottom: 5rem;
  `}
`

export const Grid = styled(UI.Grid)`
  align-items: stretch;
  height: 100%;
`

export const Cell = styled.div`
  height: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    width: ${getColumnSize(3)};
  `}
`

import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { parse } from 'query-string'
import flatMap from '@/utils/flatMap'
import getMetaImage from '@/utils/getMetaImage'
import Layout from '@/layout'
import * as UI from '@/components/UI'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Hero from '@/components/contents/ArticlesIndex/Hero'
import Featured from '@/components/contents/ArticlesIndex/Featured'
import Recent from '@/components/contents/ArticlesIndex/Recent'
import Popular from '@/components/contents/ArticlesIndex/Popular'
import Search from '@/components/contents/ArticlesIndex/Search'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'

const PopularRecentGrid = styled(UI.Grid)`
  flex-direction: row-reverse;
`

const ArticlesIndexPage = ({ data, location }) => {
  const { tag: currentTag, all: isSelectedAll } = parse(location?.search)
  const content = { ...data?.content?.data, slug: data?.content?.slug }

  const articles = data?.articles?.edges
    ?.map(({ node }) => node)
    ?.map(({ slug, data: inner }) => ({ slug, ...inner }))

  const allTags = [
    ...new Set(
      flatMap(articles, ({ tags }) =>
        tags?.map(({ tag }) => tag?.toLowerCase() || null),
      ),
    ),
  ]

  const isSearching = currentTag || isSelectedAll
  const mailchimp = { ...data?.mailchimp?.data }

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <Hero content={content} allTags={allTags} currentTag={currentTag} />
      {isSearching ? (
        <Search
          content={content}
          allArticles={articles}
          currentTag={currentTag}
          isSelectedAll={isSelectedAll}
        />
      ) : (
        <>
          <Featured content={content} />
          <UI.Container>
            <PopularRecentGrid>
              <Popular content={content} mailchimp={mailchimp} />
              <Recent content={content} articles={articles.slice(0, 4)} />
            </PopularRecentGrid>
          </UI.Container>
        </>
      )}
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArticlesIndex($locale: String) {
    content: prismicArticlesPage(lang: { eq: $locale }) {
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        hero_title
        hero_featured {
          article {
            document {
              ... on PrismicArticle {
                slug: uid
                data {
                  meta_title
                  meta_description
                  date(formatString: "DD MMMM YYYY", locale: $locale)
                  meta_image {
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
        recent_title
        recent_see_all
        popular_title
        popular_see_all
        popular_featured {
          article {
            document {
              ... on PrismicArticle {
                slug: uid
                data {
                  meta_title
                  meta_description
                  date(formatString: "DD MMMM YYYY", locale: $locale)
                  meta_image {
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
        newsletter_title
        newsletter_placeholder
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
    articles: allPrismicArticle(
      filter: { lang: { eq: $locale } }
      sort: { order: DESC, fields: data___date }
    ) {
      edges {
        node {
          slug: uid
          data {
            meta_title
            meta_description
            date(formatString: "DD MMMM YYYY", locale: $locale)
            meta_image {
              localFile {
                childImageSharp {
                  fluid {
                    aspectRatio
                    src
                    sizes
                    originalName
                    srcSet
                  }
                }
              }
            }
            tags {
              tag
            }
          }
        }
      }
    }
    mailchimp: prismicNavbar(lang: { eq: $locale }) {
      data {
        success_message
        duplicated_message
        empty_message
        deleted_message
        generic_error
      }
    }
  }
`

export default ArticlesIndexPage

import React from 'react'
import * as UI from '@/components/UI'
import {
  Section,
  TitleBar,
  Title,
  CardCell,
  CardWrapper,
  CardImageWrapper,
  CardImage,
  CardMain,
  CardDate,
  CardTitle,
  CardDescription,
  CardTags,
  CardTag,
} from './Recent.css'

const Recent = ({ content, articles }) => {
  return (
    <Section>
      <TitleBar>
        <Title>{content.recent_title}</Title>
        <UI.ArrowLink color='aqua' to={`/${content.slug}?all=true`}>
          {content.recent_see_all}
        </UI.ArrowLink>
      </TitleBar>
      <UI.Grid vertical-gutter>
        {articles.map(article => (
          <CardCell key={article.slug}>
            <CardWrapper to={`${content.slug}/${article.slug}`}>
              <CardImageWrapper>
                <CardImage alt={article.meta_title} src={article?.meta_image} />
              </CardImageWrapper>
              <CardMain>
                <CardDate>{article.date}</CardDate>
                <CardTitle>{article.meta_title}</CardTitle>
                <CardDescription>{article.meta_description}</CardDescription>
                <CardTags>
                  {article.tags.map(({ tag }) => (
                    <CardTag key={tag}>{tag}</CardTag>
                  ))}
                </CardTags>
              </CardMain>
            </CardWrapper>
          </CardCell>
        ))}
      </UI.Grid>
    </Section>
  )
}

export default Recent

import React from 'react'
import kebab from '@/utils/kebab'
import * as UI from '@/components/UI'
import { Section, Grid, Cell } from './Search.css'

const kebabTags = tags => tags?.map(({ tag }) => kebab(tag)) || []

const filter = ({ allArticles, isSelectedAll, currentTag }) =>
  isSelectedAll
    ? allArticles
    : allArticles.filter(({ tags }) => kebabTags(tags).includes(currentTag))

const Search = ({ content, allArticles = [], isSelectedAll, currentTag }) => {
  const articles = filter({ allArticles, isSelectedAll, currentTag })

  return (
    <Section>
      <UI.Container>
        {!!articles.length && (
          <Grid vertical-gutter>
            {articles.map(article => (
              <Cell key={article.slug}>
                <UI.Card
                  to={`${content.slug}/${article.slug}`}
                  color='aqua'
                  title={article.meta_title}
                  date={article.date}
                  description={article.meta_description}
                  image={article?.meta_image}
                  tags={article.tags}
                  type='article'
                />
              </Cell>
            ))}
          </Grid>
        )}
      </UI.Container>
    </Section>
  )
}

export default Search

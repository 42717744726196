import React from 'react'
import kebab from '@/utils/kebab'
import * as UI from '@/components/UI'
import { Section, Title, Tags, Tag, TagLink } from './Hero.css'

const Hero = ({ content, allTags, currentTag }) => {
  return (
    <Section>
      <UI.Container>
        <UI.Link to={`/${content.slug}`}>
          <Title>{content.hero_title}</Title>
        </UI.Link>
        {!!allTags?.length && (
          <Tags>
            {allTags.map(tag => (
              <Tag key={tag} current={kebab(currentTag) === kebab(tag)}>
                <TagLink to={`/${content.slug}?tag=${kebab(tag)}`}>
                  {tag}
                </TagLink>
              </Tag>
            ))}
          </Tags>
        )}
      </UI.Container>
    </Section>
  )
}

export default Hero
